<template>
  <v-container class="px-0 py-0">
    <v-container
      :style="tripDay.selected == false ? 'background:white' : 'background:#bbb'"
      class="mt-4"
    >
      <div class="d-flex flex-row justify-space-between">
        <p style="font-size: 14px; line-height: 20px; font-weight: 500" class="text-uppercase">
          <span>{{ tripDay.index }}</span> Өдөр   
        </p>
        <v-chip
          label
          x-small
          style="font-size: 12px; line-height: 18px; cursor: pointer"
          class="orange lighten-2 py-3"
          dark
          >Газар+</v-chip
        >
      </div>
    </v-container>
    <v-divider></v-divider>
    <v-list two-line>
      <draggable
        v-model="tripDay.locations"
        handle=".handle"
        group="a"
        v-if="tripDay.locations.length > 0"
      >
        <template>
          <TripItem
            v-for="(location, index) in tripDay.locations"
            :key="index"
            :location="location"
            v-bind="$attrs"
            :tripDay = "tripDay"
          ></TripItem>
        </template>
      </draggable>
    </v-list>
  </v-container>
</template>
<script>
import TripItem from "./TripItem.vue";
import draggable from "vuedraggable";
export default {
  components: {
    TripItem,
    draggable,
  },
  props: { tripDay: Object },
  methods: {
    _addPlace() {
      console.log("dlfksdf");
      this.tripDay.selected = !this.tripDay.selected;
      this._addPlace2();
    },
  },
  created() {
    console.log(this.tripDay)
    this.tripDay.selected = false;
  },
};
</script>